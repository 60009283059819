@font-face {
	font-family: RalewayItalic;
	src: url(./assets/Raleway-Italic-VariableFont_wght.ttf);
}
@font-face {
	font-family: Raleway;
	src: url(./assets/Raleway-VariableFont_wght.ttf);
}
.App {
  width: 100%;
  font-family: Raleway;
  overflow: hidden;
}
h1,h2,h3,h4,h5,p{margin: 0;}
.Header{
  width: 100%;
  aspect-ratio: 2.75;
  background-image: url(./assets/sape-header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.SMIcon {
  width: 6vw;
  aspect-ratio: 1;
  background-color: #693318;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  transition: all 150ms linear;
}
.SMContainer{
  display: flex;
  flex-direction: row;
  gap: 1vw;
}
.SMIcon:hover {
  cursor: pointer;
  background-color: #4b2411;
  scale: 110%;
  rotate: 2deg;
}
.XLogo {
  background-image: url(./assets/Xlogo.png);
}
.TelegramLogo {
  background-image: url(./assets/Telegramlogo.png);
}
.BuyBtn{
  color: #693318;
  background-color: #ffc100;
  border: #693318 0.2vw solid;
  display: flex;flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  border-radius: 0.4vw;
  font-weight: 800;
  font-size:3.472vw;
  width: 25vw;
  aspect-ratio: 4.5;
  transition: all 100ms linear;
}
.BuyBtn:hover{
  scale: 1.05;
  cursor: pointer;
  background-color: #ffdd78;

}
.SideActions{
  display: flex;
  flex-direction: column;
  gap: 1vw;
  position: relative;
}
.SideActionContainer{
  width: fit-content;
  height: fit-content;
  padding: 1vw;
  padding-left: 2vw;
  position: absolute;
  background-color: #f87400;
  background-image: linear-gradient(135deg, #f87400,#bc691f);
  top: 32vw;
  left: -1vw;
  z-index: 5;
  border-radius: 0 2vw 2vw 0;
  border: #fff 0.25vw solid;
  outline: #f87400 0.25vw solid;
}
.SideDescriptor{
  position: absolute;
  top: 35vw;
  right: 0;
  border: #fff 0.25vw solid;
  outline: #ffdc01 0.25vw solid;
  background-color: #fff102;  
  background-image: url(./assets/sunrays.png);
  background-size: 120%;
  background-position:0% 70%;
  border-radius: 2vw 0 0 2vw;
  width: 75%;
  height: 20vw;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center ;
}
.SideDescriptor img{
  height: 100%;
  position: relative;
  scale: 1.25;
  transform-origin: bottom right;
  right: 0;
}
.SideDescriptorInfoC{
  padding-left: 5vw;
  width: 70%;
  text-transform: uppercase;

}
.SideDescriptorInfoC h1{
  font-size: 5.8vw;
  font-weight: 900;
  text-shadow: #fff 0.25vw 0.25vw 0;
  color: #361402;
}
.SideDescriptorInfoC h2{
  font-size: 1.5vw;
  font-weight: 500;
  width: 85%;
}
.SideAnimation{
  width: 80%;
  aspect-ratio: 1.6666;
  background-image: url(./assets/animation.GIF);
  background-size: cover;
  position: absolute;
  top: 45vw;
  left: -1vw;
  border: #fff 0.25vw solid;
  outline: #f87400 0.25vw solid;
  border-radius: 0 2vw 2vw 0;
}
.SideVertical{
  width: 22vw;
  aspect-ratio: 0.5;
  position: absolute;
  top: 45vw;
  right: 0;
  background-image: url(./assets/sape-header.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  border: #fff 0.25vw solid;
  outline: #f87400 0.25vw solid;
  border-radius:  0 0 0 2vw;
}
.buffer{
  width: 100%;
  height: 53vw;
  background-color: #123456;
}
.TitleText{
  font-weight: 900;
  text-shadow: #361402 0.6vw 0.6vw 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 8vw;
  margin-left: 1vw;
}

.paddingtop5vw{
  padding-top: 5vw;
}
.Tokenomics{
  width: 100%;
  aspect-ratio: 2.1;
  background-image: url(./assets/2.png),url(./assets/brickwall.png);
  background-repeat: no-repeat;
  background-position: right, center;
  background-size: contain,cover;
  padding: 1vw 0;
}

.TokenomicsDataContainer{
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
  width: 45%;
  gap: 1vw;
  color: #fff;
}
.TokenomicsDataItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  aspect-ratio: 4;
  background-image: linear-gradient(135deg, hsla(210, 80%, 66%, 0.502), hsla(210, 46%, 50%, 0.5));
  backdrop-filter: blur(1vw);
  border-radius: 2vw;
  box-shadow: #63a8ee5b 0.2vw 0.2vw 1vw;
  transition: all 100ms linear;
}
.TokenomicsDataItem:hover{
  scale: 1.02;
}
.TokenomicsDataItem h2{
  margin-top: 1vw;
  font-size: 4vw;
  font-weight: 800;
  text-transform: uppercase;
}
.TokenomicsDataItem h3{
  margin-top: 1vw;
  font-size: 1.5vw;
  font-weight: 600;
}
.CopyBtn{
  background-color: hsla(210, 70%, 30%, 0.7);
  padding: 0.7vw 8vw;
  margin: 1vw 0;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2vw;
  transition: all 100ms linear;

}
.CopyBtn:hover{
  background-color: hsl(210, 93%, 41%);
  cursor: pointer;
}
.Seperator{
  width: 110%;
  height: 4vw;
  rotate: -2deg;
  margin-left: -5%;
  margin-top: -2vw;
  background-color: #260b18;
  border: #68555e 0.5vw solid;
}
.Ticker{
  text-wrap: nowrap;
  color: #fff;
  font-size: 3.125vw;
  text-align: left;
  animation: tickerAnimation 30s linear 0s infinite;
}
@keyframes tickerAnimation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-300%);
    /* Move to the left end */
  }
}
.Guide{
  width: 100%;
  aspect-ratio: 2.1;
  padding: 3vw 0 1vw 0;
  margin-top: -2vw;
  background-image: url(./assets/3.png),linear-gradient(-135deg, #ffe99a,hsl(47, 50%, 59%));
  background-repeat: no-repeat;
  background-position: right bottom, center;
  background-size: contain;
  border-bottom: #000 0.5vw solid;
}
.GuideDataContainer{
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
  gap:1vw
}
.GuideDataItem{
  width: 95%;
  aspect-ratio: 12;
  background-color: #36140246;
  backdrop-filter: blur(0.2vw);
  padding: 2% 2.5%;
  color: #fff;
  transform-origin: left;
  box-shadow: #00000028 0.2vw 0.2vw 1vw;
  transition: all 200ms linear;
}
.GuideDataItem:hover{
  scale: 1.1;
  cursor:default;
}
.GuideDataItem h1{
  font-weight: 800;
  text-shadow: #36140299 0.15vw 0.15vw 0;
  font-size: 3vw;

}
.GuideDataItem h3{
  font-weight: 500;
  font-size: 1.5vw;
  padding: 0.5% 0;
}
.PhantomLink{
  color: #fff;
  font-weight: 900;
}
.PhantomLink:hover{
  color: #fdff69;
}
.Socials{
  width: 100%;
  aspect-ratio: 2.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(135deg, #ecb42d,#c19122);
}

.imgContainer{
  width: 120%;
  display: flex;
  flex-direction: row;
  margin-top: 2vw;
  align-items: center;
  justify-content: center;
}
@keyframes subtleRotate1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes subtleRotate2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes subtleRotate3 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes subtleRotate4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.imgHolder {
  width: 25vw;
  aspect-ratio: 1;
  background-color: #000;
  border: #fff 1vw solid;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0.156vw 0.417vw;
  transition: all 150ms linear;
  background-size: contain;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.imgHolder:hover{
  scale: 1.2;
  rotate:unset;
}
.img1 {
  background-image: url(./assets/7.png);
  animation-name: subtleRotate1;
}

.img2 {
  background-image: url(./assets/5.png);
  animation-name: subtleRotate2;
}

.img3 {
  background-image: url(./assets/6.png);
  animation-name: subtleRotate3;
}

.img4 {
  background-image: url(./assets/4.png);
  animation-name: subtleRotate4;
}.r2deg{rotate: 2deg;}
.r5deg{rotate: 5deg;}
.r4degr{rotate: -4deg;}
.r3degr{rotate: -3deg;}
.Footer{
  margin-top: 2vw;
  font-size: 1vw;
}
